import Player from '@vimeo/player';

const start = Date.now();

document.addEventListener('DOMContentLoaded', () => {
	const main = document.querySelector('main');
	const header = main.querySelector('header');
	const bgVideo = header.querySelector('video');
	const playButton = header.querySelector('button');
	const intro = main.querySelector('section.intro');
	const callout = main.querySelector('section.callout');
	const calloutVideo = callout.querySelector('video');
	const outro = main.querySelector('section.outro');
	const footer = main.querySelector('footer');
	const viewObservers = [
		[intro, 'intro-in-view', 0.5],
		[callout, 'callout-in-view', 0.5],
		[outro, 'outro-in-view', 0.9],
		[outro, 'footer-in-view', 0.7],
	].map(([section, className, threshold]) => (
		new IntersectionObserver(([{ isIntersecting }]) => {
			document.body.classList.toggle(className, isIntersecting);
		}, { threshold })
	).observe(section));
	const modal = document.querySelector('.modal');
	const closeButton = modal.querySelector('button');
	const videoPlayer = new Player(modal.querySelector('div'), {
		url: 'https://vimeo.com/896617539/9ca0917f8e?h=c08cfb1083',
		// loop: true,
	});
	const setOutroHeight = () => {
		outro.style.minHeight = `calc(100vh - ${footer.offsetHeight}px)`
	};

	window.addEventListener('resize', () => { setOutroHeight(); }, { passive: true });
-	document.addEventListener('keyup', ({ keyCode }) => {
		if (keyCode === 27) {
			document.body.classList.remove('modal-is-active');
			videoPlayer.pause();
		}
	});
	main.addEventListener('scroll', () => {
		const { top } = callout.getBoundingClientRect();
		const shift = 200 * top / main.offsetHeight;

		calloutVideo.style.transform = `translateY(${shift}px)`;
	}, { passive: true });
	bgVideo.onplaying = e => {
		document.body.classList.add('is-loaded');

		setTimeout(() => {
			document.body.classList.add('is-revealed');
		}, 700); // var(--a-t-lg)
	};
	playButton.onclick = () => {
		document.body.classList.add('modal-is-active');
		videoPlayer.play();
	}
	closeButton.onclick = () => {
		document.body.classList.remove('modal-is-active');
		videoPlayer.pause();
	}

	setOutroHeight();
	setTimeout(() => {
		bgVideo.play();
	}, 3000 - (Date.now() - start));
});
